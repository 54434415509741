import { sanitize } from 'isomorphic-dompurify';
import ReactHtmlParser, { Options } from 'react-html-parser';

// --------------------------------------------------------------------------

export const parseSanitizeItem = (
  item: string | React.ReactNode,
  options?: Options,
) => {
  if (typeof item === 'string') {
    return ReactHtmlParser(sanitize(item), options);
  }
  return item;
};
