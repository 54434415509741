import {
  BannerLink,
  Close,
  CloseButton,
  CloseButtonWrapper,
  DialogContent,
  DialogDescriptionText,
  DialogOverlay,
  DialogRoot,
  DialogTitle,
  TitleContainer,
} from './banner-emergency.style';
import { parseSanitizeItem } from '@exploration/ui-utils';
import * as Dialog from '@radix-ui/react-dialog';

interface ModalProps {
  title: string;
  content: string;
  isOpen: boolean;
  onClose: () => void;
  link?: {
    text: string;
    href: string;
  };
}

export const Modal = ({
  title,
  content,
  isOpen,
  onClose,
  link,
}: ModalProps) => {
  return (
    <DialogRoot open={isOpen} onOpenChange={(open) => open || onClose()}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent onInteractOutside={onClose} onEscapeKeyDown={onClose}>
            <TitleContainer>
              <DialogTitle as={'span'}>{parseSanitizeItem(title)}</DialogTitle>
              <CloseButtonWrapper>
                <CloseButton
                  role="button"
                  aria-label="Close Modal"
                  onClick={onClose}
                >
                  <Close />
                </CloseButton>
              </CloseButtonWrapper>
            </TitleContainer>
            <DialogDescriptionText as={'p'}>{content}</DialogDescriptionText>
            {link && <BannerLink href={link.href}>{link.text}</BannerLink>}
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </DialogRoot>
  );
};
