import { SectionContainer } from '@exploration/shared-components';
import { CloseIcon, MDSButton, MDSText } from '@midwest/web/base';
import { MDSLink } from '@midwest/web/link';
import * as Dialog from '@radix-ui/react-dialog';
import { desktop, tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const Section = styled.section<{
  dotcomSiteContent?: boolean;
}>`
  background-color: ${(p) =>
    p.dotcomSiteContent
      ? `${p.theme.midwestColors.componentContainerColorBackgroundInfoSubtle}`
      : `inherit`};
`;

export const Container = styled(SectionContainer)`
  display: flex;
  justify-content: center;
  min-height: auto;
  padding-block: 0.5rem;
  position: relative;
  flex-direction: row;
  ${tablet} {
    align-items: center;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  ${tablet} {
    align-items: center;
    flex-direction: column;
  }
`;

export const BannerRow = styled.div<{ showBorder: boolean }>`
  display: grid;
  grid-template-rows: auto;
  padding-right: 2rem;
  ${tablet} {
    grid-template-columns: auto 10rem;
    width: 100%;
    padding-right: 0;
  }
`;

export const Text = styled(MDSText.BodyHeavySm)`
  margin: 0;
  a {
    font-size: inherit;
    text-decoration: underline;
  }
`;

export const Button = styled(MDSButton)`
  margin: 0;
  ${desktop} {
    margin: 0 1rem;
  }
  text-decoration: none;
`;

export const CloseButtonWrapper = styled('span')`
  max-height: 1.5rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
`;

export const CloseButton = styled.button`
  margin-right: -1rem;
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const Close = styled(CloseIcon ?? null)`
  path {
    fill: ${(p) => p.theme.midwestColors.textSecondary};
  }
`;

export const DialogRoot = styled(Dialog.Root)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(p) => p.theme.midwestColors.white};
`;

export const DialogTrigger = styled(Dialog.Trigger)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

export const DialogOverlay = styled(Dialog.Overlay)`
  background-color: rgba(54, 54, 54, 0.5);
  position: fixed;
  inset: 0;
  z-index: 5;
  @media screen and (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: overlayShow 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state='closed'] {
      animation: overlayHide 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }
  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes overlayHide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const DialogContent = styled(Dialog.Content)`
  width: calc(100vw - 64px);
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  background-color: ${(p) => p.theme.midwestColors.backgroundPrimary};
  padding: 1rem;
  ${tablet} {
    min-width: 480px;
    max-width: 600px;
    padding: 1.5rem;
  }

  @media screen and (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: contentShow 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state='closed'] {
      animation: contentHide 1s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }
  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
  @keyframes contentHide {
    from {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
  }
`;

export const DialogTitle = styled(MDSText.HeroSm)`
  color: ${(p) => p.theme.midwestColors.textPrimary};
  margin-left: 1rem;
  a {
    font-size: inherit;
  }
`;

export const DialogDescriptionText = styled(MDSText.BodyRegDefault)`
  color: ${(p) => p.theme.midwestColors.textPrimary};
  margin-left: 1rem;
  ${tablet} {
    text-align: left;
  }
`;

export const BannerLink = styled(MDSLink)`
  padding-top: 0.5rem;
  text-align: left;
  margin-left: 1rem;
`;

export const TitleContainer = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;
