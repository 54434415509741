'use client';

import {
  BannerRow,
  Button,
  Close,
  CloseButton,
  CloseButtonWrapper,
  Container,
  MainContent,
  Section,
  Text,
} from './banner-emergency.style';
import { Modal } from './modal';
import { BannerMessages } from '@exploration/shared-types';
import { parseSanitizeItem } from '@exploration/ui-utils';
import { getCookie, setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

const COOKIE_MAX_AGE = 60 * 60 * 24;

export const BannerEmergency = ({
  banners,
  dotcomSiteContent,
}: {
  banners?: BannerMessages[] | null;
  dotcomSiteContent?: boolean;
}) => {
  const [showModal, setShowModal] = useState<string | undefined>(undefined);
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const bannerHide = getCookie('BannerHide');
    if (bannerHide) {
      setShowBanner(false);
    }
  }, []);

  const handleClose = () => {
    setCookie('BannerHide', true, { maxAge: COOKIE_MAX_AGE });
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }
  const showLearnMore = (bannerMessage: BannerMessages) => {
    return (
      typeof bannerMessage.body === 'string' && bannerMessage.body.length > 0
    );
  };

  return (
    <Section dotcomSiteContent={dotcomSiteContent}>
      <Container>
        <MainContent>
          {banners &&
            banners.map((bannerMessage, index) => (
              <BannerRow
                key={bannerMessage.id}
                showBorder={banners.length !== index + 1}
              >
                <Text>{parseSanitizeItem(bannerMessage.title)}</Text>
                {showLearnMore(bannerMessage) && (
                  <Button
                    size="small"
                    variant="ghost"
                    aria-label="Learn More"
                    onClick={() => setShowModal(bannerMessage.id)}
                  >
                    Learn more
                  </Button>
                )}
                {typeof bannerMessage.body === 'string' && (
                  <Modal
                    isOpen={showModal === bannerMessage.id}
                    onClose={() => setShowModal(undefined)}
                    title={bannerMessage.title}
                    content={bannerMessage.body}
                    link={bannerMessage.link}
                  />
                )}
              </BannerRow>
            ))}
        </MainContent>
        <CloseButtonWrapper>
          <CloseButton
            role="button"
            aria-label="Close Banner"
            onClick={handleClose}
          >
            <Close />
          </CloseButton>
        </CloseButtonWrapper>
      </Container>
    </Section>
  );
};
