import { colors } from '@thrivent/midwest-shared';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;

const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerStyles = styled.div<{ $variant: 'dark' | 'light' }>`
  height: 25px;
  width: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid;
  border-color: ${({ $variant }) =>
    $variant === 'dark'
      ? colors.identityBrandPrimaryWeak
      : 'rgba(255, 255, 255, 0.3)'};
  border-top: 4px solid;
  border-top-color: ${({ $variant }) =>
    $variant === 'dark' ? colors.identityBrandPrimaryHeavy : '#fff'};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;
/**
 ```tsx
 import { Spinner } from '@midwest/web/base';
 ```
 * Spinner is used to indicate that a page or component is loading or processing.
 */
interface Props {
  /**
   * The color of the spinner.
   */
  variant?: 'dark' | 'light';
}
export const Spinner = ({ variant = 'light' }: Props) => {
  return (
    <SpinnerContainer role="alert" aria-label="loading">
      <SpinnerStyles
        data-testid="spinner"
        aria-busy="true"
        aria-live="polite"
        $variant={variant}
      />
    </SpinnerContainer>
  );
};
