import {
  Dispatch,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEventHandler,
  SetStateAction,
} from 'react';

export const handleDisabledEvent: MouseEventHandler<HTMLButtonElement> = (
  event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
) => {
  event.stopPropagation();
  event.preventDefault();
};

export const handleKeyboardSubmitEvent = (
  event: React.KeyboardEvent<HTMLButtonElement> & { key: string },
  onClick: () => void,
) => {
  if (event.key === 'Enter' || event.key === ' ') {
    onClick();
  }
};

export const handleKeyDownCaptureDisabled: KeyboardEventHandler<
  HTMLButtonElement
> = (event: React.KeyboardEvent<HTMLButtonElement>) => {
  if (event.defaultPrevented) {
    return; // Do nothing if the event was already processed
  }
  switch (event.key) {
    case 'Tab':
      return;
      break;
    case 'Enter':
      event.stopPropagation();
      event.preventDefault();
      break;
    case 'Spacebar':
      event.stopPropagation();
      event.preventDefault();
      break;
    case ' ':
      event.stopPropagation();
      event.preventDefault();
      break;
    default:
      return; // Quit when this doesn't handle the key event.
  }

  // Cancel the default action to avoid it being handled twice
  event.stopPropagation();
  event.preventDefault();
};

export const handleKeyDownCapture: KeyboardEventHandler<HTMLButtonElement> = (
  event: React.KeyboardEvent<HTMLButtonElement>,
) => {
  switch (event.key) {
    case 'Tab':
      return;
      break;
    case 'Enter':
      return;
      break;
    case 'Spacebar':
      return;
      break;
    case ' ':
      return;
      break;
    default:
      return;
  }
};

export const focusNextElement = (e: KeyboardEvent) => {
  const focusableElements =
    'a:not([disabled]), button:not([disabled]), input:not([type=hidden]):not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';

  if (document.activeElement) {
    const focusable = Array.prototype.filter.call(
      document.querySelectorAll(focusableElements),
      (element) => {
        //check for visibility while always include the current activeElement
        return (
          element.offsetWidth > 0 ||
          element.offsetHeight > 0 ||
          element === document.activeElement
        );
      },
    );
    const index = focusable.indexOf(document.activeElement);
    if (index > -1) {
      const nextElement: HTMLElement =
        focusable[e.shiftKey ? index - 1 : index + 1] || focusable[0];
      nextElement.focus();
      e.preventDefault();
    }
  }
};

export const handleEnterEscTab = (
  e: KeyboardEvent,
  setOpen: Dispatch<SetStateAction<boolean>>,
  isOpen: boolean,
) => {
  if (e.key === 'Escape' && isOpen) {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!isOpen);
  } else if (e.key === 'Tab') {
    !isOpen && focusNextElement(e);
  }
};

export const arrowKeyHandler = (
  e: React.KeyboardEvent,
  groupId: string,
  currentIndex: number,
  stateArray: Array<unknown>,
  horizontal: boolean,
) => {
  const getNextIndex = (): number => {
    return currentIndex <= stateArray.length
      ? currentIndex + 1
      : stateArray.length;
  };

  const getPrevIndex = (): number => {
    return currentIndex > 0 ? currentIndex - 1 : 0;
  };

  if (
    (e.key === 'ArrowRight' && horizontal) ||
    (e.key === 'ArrowDown' && !horizontal)
  ) {
    e.preventDefault();
    document.getElementById(groupId + getNextIndex().toString())?.focus();
  } else {
    if (
      (e.key === 'ArrowLeft' && horizontal) ||
      (e.key === 'ArrowUp' && !horizontal)
    ) {
      e.preventDefault();
      document.getElementById(groupId + getPrevIndex().toString())?.focus();
    }
  }
};
