import { loadingTransition } from './Loader';
import { BaseProps } from './types';
import {
  border,
  colors,
  modifyPixelToken,
  primaryFont,
  radius,
  spacing,
  system,
} from '@thrivent/midwest-shared';
import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div<{ $disabled?: boolean }>`
  display: flex;
  ${(p) =>
    p.$disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const BaseButtonStyle = css<BaseProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${spacing.buttonPadding};
  border-radius: ${radius.buttonRadius};
  font-family: ${primaryFont};
  font-weight: ${system.fontWeight['primary']['heavy'].value};
  font-size: ${system.size['md']['01'].value}rem;
  text-align: center;
  letter-spacing: 0;
  border: none;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  pointer-events: all;
  width: fit-content;
  ${(p) =>
    p.disabledStyle &&
    css`
      pointer-events: none;
    `}
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  ${(p) =>
    p.fullWidth === true &&
    css`
      width: 100%;
    `}
  ${loadingTransition}
  ${(p) =>
    p.size === 'small' &&
    css`
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
      line-height: 1.25;
    `}
${(p) =>
    p.size === 'large' &&
    css`
      padding: 1.5rem 2rem;
    `}
`;

export const PrimaryButtonStyle = css<BaseProps>`
  color: ${colors.textPrimaryDarkBG};
  background-color: ${colors.identityBrandPrimaryMedium};
  border: ${border.widthDefault} solid ${colors.identityBrandPrimaryMedium};

  &:active {
    background-color: ${colors.identityBrandPrimaryHeavy};
    border: ${border.widthDefault} solid ${colors.identityBrandPrimaryHeavy};
  }
  &:focus-visible {
    box-shadow: 0 0 1px 0.25rem ${colors.identityBrandPrimaryDense};
    outline: ${colors.identityBrandPrimaryMedium};
  }
  /* stylelint-disable-next-line a11y/selector-pseudo-class-focus */
  &:hover {
    background-color: ${colors.identityBrandPrimaryStrong};
    border: ${border.widthDefault} solid ${colors.identityBrandPrimaryStrong};
  }
  /* loading state and disable state conflict, so only show one at a time */
  ${(p) =>
    p.$loading &&
    css`
      /* there is a brief period while the spinner opacity is animating, */
      /* so we have to set background color on the button even though the spinner covers */
      /* the whole button  */
      background-color: ${colors.identityBrandPrimaryHeavy};
      border: ${border.widthDefault} solid ${colors.identityBrandPrimaryHeavy};
      color: ${colors.identityBrandPrimaryHeavy};
      cursor: default;
    `}

  ${(p) =>
    p.disabledStyle &&
    !p.$loading &&
    css`
      color: ${colors.textDisabled};
      background-color: ${colors.backgroundSecondary};
      border: ${border.widthDefault} solid ${colors.neutralLightStrong};
      cursor: not-allowed;
      box-shadow: 0 0 0 0 transparent;
    `}
`;

export const SecondaryButtonStyle = css<BaseProps>`
  padding: ${(p) =>
    p.size === 'normal' && p.disabledStyle
      ? `${spacing.buttonPadding}`
      : p.size === 'large' && !p.disabledStyle
        ? '23px 31px'
        : p.size === 'normal'
          ? modifyPixelToken(spacing.buttonPadding, -1)
          : p.size === 'small' && !p.disabledStyle && '7px 11px'};
  color: ${colors.textSecondary};
  border: ${border.widthEmphasised} solid ${colors.neutralDarkStrong};
  background-color: transparent;
  &:focus-visible {
    box-shadow: 0 0 1px 0.25rem ${colors.identityBrandPrimaryDense};
    outline: ${colors.identityBrandPrimaryMedium};
  }
  &:active {
    color: ${colors.neutralDarkHeavy};
    border: ${border.widthEmphasised} solid ${colors.neutralDarkHeavy};
    background-color: ${colors.neutralLightHeavy};
  }
  /* stylelint-disable-next-line a11y/selector-pseudo-class-focus */
  &:hover {
    background-color: ${colors.neutralLightStrong};
  }
  /* loading state and disable state conflict, so only show one at a time */
  ${(p) =>
    p.$loading &&
    css`
      color: ${colors.neutralLightHeavy};
      background-color: ${colors.neutralLightWeak};
      border: ${border.widthEmphasised} solid ${colors.neutralDarkHeavy};
    `}

  ${(p) =>
    p.disabledStyle &&
    !p.$loading &&
    css`
      color: ${colors.textDisabled};
      background-color: ${colors.backgroundSecondary} !important;
      border: ${border.widthDefault} solid ${colors.neutralLightStrong};
      cursor: not-allowed;
      box-shadow: 0 0 0 0 transparent !important;
    `}
`;

export const DestructiveButtonStyle = css<BaseProps>`
  border: ${border.widthDefault} solid ${colors.sentimentNegativeMedium};
  background-color: ${colors.sentimentNegativeMedium};
  color: ${colors.textPrimaryDarkBG};
  &:focus-visible {
    box-shadow: 0 0 1px 0.25rem ${colors.identityBrandPrimaryDense};
    outline: ${colors.sentimentNegativeMedium};
  }
  &:active {
    border: ${border.widthDefault} solid ${colors.sentimentNegativeHeavy};
    background-color: ${colors.sentimentNegativeHeavy};
  }
  /* stylelint-disable-next-line a11y/selector-pseudo-class-focus */
  &:hover {
    border: ${border.widthDefault} solid ${colors.sentimentNegativeStrong};
    background-color: ${colors.sentimentNegativeStrong};
  }
  /* loading state and disable state conflict, so only show one at a time */
  ${(p) =>
    p.$loading &&
    css`
      color: ${colors.sentimentNegativeHeavy};
      background-color: ${colors.sentimentNegativeHeavy};
      border: ${border.widthDefault} solid ${colors.sentimentNegativeHeavy};
    `}

  ${(p) =>
    p.disabledStyle &&
    !p.$loading &&
    css`
      color: ${colors.textSecondary};
      background-color: ${colors.neutralLightStrong} !important;
      border: ${border.widthDefault} solid ${colors.neutralLightStrong};
      cursor: not-allowed;
      box-shadow: 0 0 0 0 transparent !important;
    `}
`;

export const GhostButtonStyle = css<BaseProps>`
  color: ${colors.textLink};
  background-color: transparent;
  border: ${border.widthDefault} solid transparent;
  &:focus-visible {
    outline: ${colors.identityBrandPrimaryDense};
    box-shadow: 0 0 1px 0.25rem ${colors.identityBrandPrimaryDense};
  }
  /* stylelint-disable-next-line a11y/selector-pseudo-class-focus */
  &:hover {
    color: ${colors.identityBrandPrimaryHeavy};
    background-color: ${colors.identityBrandPrimaryFaint};
  }
  /* loading state and disable state conflict, so only show one at a time */
  ${(p) =>
    p.$loading &&
    css`
      color: ${colors.identityBrandPrimaryHeavy};
      background-color: ${colors.identityBrandPrimaryHeavy};
      border: ${border.widthDefault} solid ${colors.identityBrandPrimaryHeavy};
    `}

  ${(p) =>
    p.disabledStyle &&
    !p.$loading &&
    css`
      color: ${colors.textDisabled};
      background-color: transparent !important;
      cursor: not-allowed;
      box-shadow: 0 0 0 0 transparent !important;
    `}
`;

export const DestructiveGhostButtonStyle = css<BaseProps>`
  border: ${border.widthDefault} solid transparent;
  color: ${colors.sentimentNegativeMedium};
  background-color: transparent;
  &:focus-visible {
    outline: ${colors.identityBrandPrimaryDense};
    box-shadow: 0 0 1px 0.25rem ${colors.identityBrandPrimaryDense};
  }
  /* stylelint-disable-next-line a11y/selector-pseudo-class-focus */
  &:hover {
    background-color: ${colors.sentimentNegativeFaint};
    color: ${colors.sentimentNegativeHeavy};
  }
  &:active {
    background-color: ${colors.sentimentNegativeWeak};
    color: ${colors.sentimentNegativeHeavy};
  }
  /* loading state and disable state conflict, so only show one at a time */
  ${(p) =>
    p.$loading &&
    css`
      color: ${colors.identityBrandPrimaryHeavy};
      background-color: ${colors.identityBrandPrimaryHeavy};
      border: ${border.widthDefault} solid ${colors.identityBrandPrimaryHeavy};
    `}

  ${(p) =>
    p.disabledStyle &&
    !p.$loading &&
    css`
      color: ${colors.textDisabled};
      background-color: transparent !important;
      cursor: not-allowed;
      box-shadow: 0 0 0 0 transparent !important;
    `}
`;
