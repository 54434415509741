import { Container } from './container';
import { desktop } from '@thrivent/midwest-shared';
import styled from 'styled-components';

// --------------------------------------------------------------------------

/**
 * @deprecated - Don't use this, just use Container instead - and if you need display: flex, add it.
 */
export const SectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  ${desktop} {
    flex-direction: row;
    min-height: 25rem;
  }
`;
